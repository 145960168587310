<template>
  <div>
    <b-badge
      ref="duration-time"
      :pill="isPill"
      :variant="variant"
      :class="className"
    >
      <template v-if="rounded">
        <span class="cursor-pointer duration-time">
          {{ roundedTime(duration) }}
        </span>
      </template>
      <template v-else>
        <div class="d-flex cursor-pointer duration-time">
          <div v-if="labelDescription !== null">
            <span>{{ `${labelDescription} : ` }}</span>
          </div>
          <div>
            <span>
              {{ exactTime(duration).time }}
            </span>
          </div>
        </div>
      </template>
    </b-badge>
    <b-tooltip
      :target="() => $refs['duration-time']"
      :disabled.sync="tooltipDisabled"
      :variant="tooltipVariant"
      :placement="tooltipPosition"
    >
      <strong>{{ exactTime(duration).tooltip }}</strong>
    </b-tooltip>
  </div>
</template>
<script>
export default {
  name: "Duration",
  props: {
    duration: {
      type: Object,
      required: true,
      default: () => {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      },
    },
    isPill: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: "light-primary",
    },
    className: {
      type: String,
      default: "",
    },
    rounded: {
      type: Boolean,
      default: true,
    },

    tooltipVariant: {
      type: String,
      default: "primary",
    },
    tooltipPosition: {
      type: String,
      default: "top",
    },
    tooltipDisabled: {
      type: Boolean,
      default: false,
    },
    labelDescription: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    exactTime(time) {
      let formattedTime = "";
      let tooltip = "";
      if (time.days > 0) {
        formattedTime += `${time.days} D `;
        tooltip += `${time.days} Day${time.days > 1 ? "s" : ""} `;
      }
      if (time.hours > 0) {
        formattedTime += `${time.hours} H `;
        tooltip += `${time.hours} Hour${time.hours > 1 ? "s" : ""} `;
      }
      if (time.minutes > 0) {
        formattedTime += `${time.minutes} M `;
        tooltip += `${time.minutes} Minute${time.minutes > 1 ? "s" : ""} `;
      }
      if (time.seconds > 0) {
        formattedTime += `${time.seconds} S `;
        tooltip += `${time.seconds} Second${time.seconds > 1 ? "s" : ""} `;
      }
      return {
        time: formattedTime.trim(),
        tooltip: tooltip.trim(),
      };
    },
    roundedTime(time) {
      let formattedTime = "";
      if (time.days > 0) {
        formattedTime += `${time.days} Day${time.days > 1 ? "s" : ""} `;
      }
      if (time.hours > 0 && time.days < 1) {
        formattedTime += `${time.hours} Hour${time.hours > 1 ? "s" : ""} `;
      }
      if (time.minutes > 0 && time.hours < 1) {
        formattedTime += `${time.minutes} Minute${
          time.minutes > 1 ? "s" : ""
        } `;
      }
      if (time.seconds > 0 && time.minutes < 1) {
        formattedTime += `${time.seconds} Second${
          time.seconds > 1 ? "s" : ""
        } `;
      }

      return formattedTime.trim();
    },
  },
};
</script>
<style lang="scss">
.duration-time {
  font-size: 14px;
  padding: 1px 6px;
}
</style>

import { render, staticRenderFns } from "./UserActivityDetail.vue?vue&type=template&id=b7371726&scoped=true"
import script from "./UserActivityDetail.vue?vue&type=script&lang=js"
export * from "./UserActivityDetail.vue?vue&type=script&lang=js"
import style1 from "./UserActivityDetail.vue?vue&type=style&index=1&id=b7371726&prod&lang=scss&scoped=true"
import style2 from "./UserActivityDetail.vue?vue&type=style&index=2&id=b7371726&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7371726",
  null
  
)

export default component.exports
<template>
  <div class="container-route">
    <div class="wrapper">
      <div class="arrow-steps clearfix">
        <div
          v-for="(path, index) in navigationFiltered"
          :key="index"
          class="step current"
        >
          <span>{{ capitalizeWords(path.description) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navigationPath: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    navigationFiltered() {
      //remover si description es igual a la anterior
      return this.navigationPath.filter((item, index) => {
        return (
          index === 0 ||
          item.description !== this.navigationPath[index - 1].description
        );
      });
    },
  },
  methods: {
    capitalizeWords(str) {
      str = str.toLowerCase();
      const words = str.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join(" ");
    },
  },
};
</script>

<style lang="scss">
.container-route .clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.container-route {
  font-family: "Lato", sans-serif;
  margin: 0 auto;
}

.container-route .wrapper {
  display: table-cell;
  height: 30px;
  vertical-align: middle;
  font-weight: 500;
}

.container-route .nav {
  margin-top: 40px;
}

.pull-right {
  float: right;
}

.container-route a,
a:active {
  color: #333;
  text-decoration: none;
}

.container-route a:hover {
  color: #999;
}

.arrow-steps {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px;
  padding: 6px 6px 6px 25px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -15px;
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 15px solid #d9e3f7;
  z-index: 1;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 15px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #82868b;
  background: rgba(217, 217, 217, 0.3);
}

.arrow-steps > .step:first-child.current {
  color: rgba(63, 122, 250, 1);
  background: rgba(63, 122, 250, 0.3);
  padding: 6px 15px 6px 20px;
}

.arrow-steps > .step:last-child.current {
  color: rgba(125, 104, 212, 1);
  background: rgba(125, 104, 212, 0.3);
}

.arrow-steps .step.current:after {
  border-left: 15px solid rgba(217, 217, 217, 0.3);
}

.arrow-steps > .step.current:first-child:after {
  border-left: 15px solid rgba(63, 122, 250, 0.3);
}

.arrow-steps > .step.current:last-child:after {
  border-left: 15px solid rgba(125, 104, 212, 0.3);
}

.dark-layout {
  .container-route .clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
  }

  .container-route {
    font-family: "Lato", sans-serif;
    margin: 0 auto;
  }

  .container-route .wrapper {
    display: table-cell;
    height: 30px;
    vertical-align: middle;
    font-weight: 500;
  }

  .container-route .nav {
    margin-top: 40px;
  }

  .pull-right {
    float: right;
  }

  .container-route a,
  .container-route a:active {
    color: #333;
    text-decoration: none;
  }

  .container-route a:hover {
    color: #999;
  }

  .arrow-steps {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow-steps .step {
    font-size: 14px;
    text-align: center;
    cursor: default;
    margin: 0 3px;
    padding: 6px 6px 6px 25px;
    float: left;
    position: relative;
    background-color: #d9e3f7;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
  }

  .arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 15px solid #1b1b1e;
    z-index: 0;
  }

  .arrow-steps .step:first-child:before {
    border: none;
  }

  .arrow-steps .step span {
    position: relative;
  }

  .arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
  }

  .arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
  }

  .arrow-steps .step.current {
    color: #cccccc;
    background-color: #535354;
  }

  .arrow-steps > .step:first-child.current {
    color: #0076bd;
    background-color: #1f3158;
  }

  .arrow-steps > .step:last-child.current {
    color: #7864cc;
    background-color: #312b4d;
  }

  .arrow-steps .step.current:after {
    border-left: 15px solid #535354;
  }

  .arrow-steps > .step.current:first-child:after {
    border-left: 15px solid #1f3158;
  }

  .arrow-steps > .step.current:last-child:after {
    border-left: 15px solid #312b4d;
  }
}
</style>

<template>
  <div class="users-log-table">
    <b-nav card-header pills class="mt-1 ml-1 mb-0">
      <b-nav-item
        v-for="(moduleItem, index) in modulesList"
        :key="index"
        v-b-tooltip.hover.top="moduleItem.alias"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        style="width: 85px"
        :active="moduleItem.module_id === currentModule"
        @click="changeTabModule(moduleItem.module_id)"
      >
        <img
          :src="`/assets/${moduleItem.icons}`"
          alt="Logo"
          height="30"
          width="30"
        />
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <filter-slot
        class="filter-slot-new-customization"
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="fromPage"
        :to-page="toPage"
        :top-paginate="false"
        @reload="$refs['UsersLogTable'].refresh()"
      >
        <!-- This components will be moved to match to a different design different than the set by default -->
        <template #buttons>
          <div
            id="filter-date-selector"
            class="d-flex justify-content-between align-items-center w-80 hide-filter-date-picker"
          >
            <!-- Button Send Email -->
            <b-form-datepicker
              v-model="filterDate"
              class="ml-2 filter-date-picker"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="en"
              today-button
            />
          </div>
        </template>
        <template #table>
          <b-table
            ref="UsersLogTable"
            no-border-collapse
            class="position-relative table-new-customization"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="myProvider"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #empty>
              <div class="text-center text-primary my-2">
                <strong>No existing data.</strong>
              </div>
            </template>
            <template #head(user_name)="data">
              <div class="text-left pl-3">
                {{ data.label }}
                <feather-icon
                  icon="UserIcon"
                  size="14"
                  style="padding-left: 3px"
                />
              </div>
            </template>
            <template #head(total_duration_in_seconds)="data">
              <div class="text-center">
                {{ data.label }}
                <tabler-icon icon="RotateClockwiseIcon" size="14" />
              </div>
            </template>
            <template #head(start_login)="data">
              <div class="text-center">
                {{ data.label }}
                <feather-icon icon="LogInIcon" size="14" />
              </div>
            </template>
            <template #head(activity)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(inactivity)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template #head(end_logout)="data">
              <div class="text-center">
                {{ data.label }}
                <feather-icon icon="LogOutIcon" size="14" />
              </div>
            </template>
            <template #head(created_at)="data">
              <div class="text-center">
                {{ data.label }}
                <feather-icon icon="ClockIcon" size="14" />
              </div>
            </template>
            <template #head(login_status)="data">
              <div class="text-center">
                {{ data.label }}
                <feather-icon icon="ActivityIcon" size="14" />
              </div>
            </template>
            <template #cell(main_module_name)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  v-if="data.item.main_module_icon"
                  :src="`/assets/${data.item.main_module_icon}`"
                  variant="light-primary"
                  class="mr-1"
                />
                <b-avatar
                  v-else
                  icon="house-door-fill"
                  variant="light-primary"
                  class="mr-1"
                />
                {{ data.item.main_module_name || "HOME" }}
              </div>
            </template>
            <template #cell(user_name)="data">
              <div
                class="cursor-pointer"
                @click="
                  openUserActivityModal(
                    data.item.user_id,
                    data.item.user_thumb,
                    data.item.user_name
                  )
                "
              >
                <div style="font-size: 13px">
                  <b-avatar
                    :src="data.item.user_thumb"
                    size="1.8rem"
                    variant="light-primary"
                    class="mr-1"
                  />
                  {{ data.item.user_name }}
                </div>
              </div>
            </template>
            <template #cell(start_login)="data">
              <div
                class="text-center"
                style="font-weight: 900; font-size: 14px"
              >
                <span v-if="data.item.start_login !== null">
                  {{ data.item.start_login | myGlobalDay }}
                </span>
                <span v-else> - </span>
              </div>
            </template>
            <template #cell(activity)="data">
              <div class="text-center">
                <div
                  v-if="data.item.user_activity_in_seconds !== null"
                  @click="openUserValidActivityModal(data.item.user_id, data.item.user_thumb, data.item.user_name)"
                >
                  <duration-time
                    :duration="{
                      days: data.item.activity_duration_in_days,
                      hours: data.item.activity_duration_in_hours,
                      minutes: data.item.activity_duration_in_minutes,
                      seconds: data.item.activity_duration_in_seconds,
                    }"
                    :is-pill="true"
                    :rounded="false"
                    :variant="'light-success'"
                    :tooltip-variant="'success'"
                  />
                </div>
                <span v-else> - </span>
              </div>
            </template>
            <template #cell(inactivity)="data">
              <div class="text-center">
                <div
                  v-if="
                    data.item.user_inactivity_in_seconds !== null &&
                    data.item.user_inactivity_in_seconds > 120
                  "
                  @click="openUserValidAbsenceModal(data.item.user_id, data.item.user_thumb, data.item.user_name)"
                >
                  <duration-time
                    :duration="{
                      days: data.item.inactivity_duration_in_days,
                      hours: data.item.inactivity_duration_in_hours,
                      minutes: data.item.inactivity_duration_in_minutes,
                      seconds: data.item.inactivity_duration_in_seconds,
                    }"
                    :is-pill="true"
                    :rounded="false"
                    :variant="'light-danger'"
                    :tooltip-variant="'danger'"
                  />
                </div>
                <span v-else> - </span>
              </div>
            </template>
            <template #cell(end_logout)="data">
              <div
                class="text-center"
                style="font-weight: 900; font-size: 14px"
              >
                <span v-if="data.item.end_logout !== null">
                  {{ data.item.end_logout | myGlobalDay }}
                </span>
                <span v-else> - </span>
              </div>
            </template>
            <template #cell(total_duration_in_seconds)="data">
              <div class="text-center" style="font-weight: 900">
                <div v-if="!data.item.activity_path">
                  <b-badge pill variant="light-warning">
                    <span class="text-center duration-time"> Offline </span>
                  </b-badge>
                </div>
                <div
                  v-else-if="data.item.total_duration_in_seconds !== null"
                  class="text-center"
                  style="font-weight: 900"
                >
                  <duration-time
                    :duration="{
                      days: data.item.duration_in_days,
                      hours: data.item.duration_in_hours,
                      minutes: data.item.duration_in_minutes,
                      seconds: data.item.duration_in_seconds,
                    }"
                  />
                </div>
                <div v-else>
                  <b-badge pill variant="light-success">
                    <span class="text-center duration-time">
                      {{ "On Screen" }}
                    </span>
                  </b-badge>
                </div>
              </div>
            </template>
            <template #cell(created_at)="data">
              <div v-if="data.item.activity_path !== null" class="text-center">
                <time-lapse :date="data.item.created_at" />
              </div>
            </template>
            <template #cell(login_status)="data">
              <div class="text-center">
                <div v-if="data.item.login_status === 1">
                  <b-badge pill variant="light-success">
                    <span class="text-center duration-time"> Online </span>
                  </b-badge>
                </div>
                <div v-else>
                  <b-badge pill variant="light-warning">
                    <span class="text-center duration-time"> Offline </span>
                  </b-badge>
                </div>
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>
    <user-activity-detail
      v-if="showUserActivityModal"
      :user-id="currentUserId"
      :user-photo="currentUserPhoto"
      :user-name="currentUserName"
      :date="filterDate"
      @close="closeUserActivityModal"
    />
    <user-valid-activity
      v-if="showUserValidActivityModal"
      :user-id="currentUserId"
      :user-photo="currentUserPhoto"
      :user-name="currentUserName"
      :date="filterDate"
      @close="closeUserValidActivityModal"
    />
    <user-valid-absence
      v-if="showUserValidAbsenceModal"
      :user-id="currentUserId"
      :user-photo="currentUserPhoto"
      :user-name="currentUserName"
      :date="filterDate"
      @close="closeUserValidAbsenceModal"
    />
  </div>
</template>

<script>
import moment from 'moment';
import Filters from '@/views/management/views/users-log/filters/users-log.filters';
import UsersLogService from '@/views/management/views/users-log/services/users-log.service';
import TimeLapse from '@/views/management/views/users-log/components/TimeLapse.vue';
import UserActivityDetail from '@/views/management/views/users-log/components/UserActivityDetail.vue';
import RoutePart from '@/views/management/views/users-log/components/RoutePart.vue';
import durationTime from '@/views/management/views/users-log/components/duration.vue';
import UserValidActivity from '@/views/management/views/users-log/components/UserValidActivity.vue';
import UserValidAbsence from '@/views/management/views/users-log/components/UserValidAbsence.vue';

export default {
  components: {
    TimeLapse,
    UserActivityDetail,
    RoutePart,
    durationTime,
    UserValidActivity,
    UserValidAbsence,
  },
  data() {
    return {
      currentModule: 6,
      currentDate: null,
      currentUserId: null,
      currentUserPhoto: null,
      currentUserName: null,
      fields: [
        {
          key: "main_module_name",
          label: "User Module",
          sortable: false,
          visible: true,
        },
        {
          key: "user_name",
          label: "User",
          sortable: false,
          visible: true,
        },
        {
          key: "start_login",
          label: "Start Login",
          sortable: false,
          visible: true,
        },
        {
          key: "activity",
          label: "Activity",
          sortable: false,
          visible: true,
        },
        {
          key: "inactivity",
          label: "Inactivity",
          sortable: false,
          visible: true,
        },
        {
          key: "end_logout",
          label: "End Login",
          sortable: false,
          visible: true,
        },
        {
          key: "login_status",
          label: "Status",
          sortable: false,
          visible: true,
        },
      ],
      filters: Filters,
      filterDate: null,
      startPage: 0,
      fromPage: 0,
      toPage: 0,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by user...",
        model: "",
      },
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      defaultUserOptions: {
        user_id: null,
        user_name: "All users",
      },
      defaultModuleOptions: {
        id: null,
        name: "All modules",
      },
      showUserActivityModal: false,
      showUserValidAbsenceModal: false,
      intervalId: null,
      modulesList: [
        {
          module_id: 6,
          module_name: "CE",
          alias: "Credit Experts",
          icons: "/images/modules-icons/creditexperts.png",
        },
        {
          module_id: 7,
          module_name: "BC",
          alias: "Boots Credit",
          icons: "/images/modules-icons/bootscredit.png",
        },
        {
          module_id: 3,
          module_name: "BU",
          alias: "Business",
          icons: "/images/modules-icons/business.png",
        },
        {
          module_id: 4,
          module_name: "ADM",
          alias: "Administration",
          icons: "/images/modules-icons/administration.png",
        },
        {
          module_id: 2,
          module_name: "CRM",
          alias: "C.R.M",
          icons: "/images/modules-icons/crm.png",
        },
        {
          module_id: 5,
          module_name: "DS",
          alias: "Debt Solution",
          icons: "/images/modules-icons/debtsolution.png",
        },
        {
          module_id: 15,
          module_name: "SN",
          alias: "Metamedia",
          icons: "/images/modules-icons/socialnetwork.png",
        },
        {
          module_id: 12,
          module_name: "PA",
          alias: "Paragon",
          icons: "/images/modules-icons/paragon.png",
        },
        {
          module_id: 18,
          module_name: "QUA",
          alias: "Quality",
          icons: "/images/modules-icons/quality.png",
        },
        {
          module_id: 11,
          module_name: "SP",
          alias: "Specialists",
          icons: "/images/modules-icons/specialists.png",
        },
        {
          module_id: 8,
          module_name: "TR",
          alias: "Tax Research",
          icons: "/images/modules-icons/taxresearch.png",
        },
        {
          module_id: 9,
          module_name: "CO",
          alias: "Correspondence",
          icons: "/images/modules-icons/correspondence.png",
        },
        {
          module_id: 14,
          module_name: "KB",
          alias: "Keybook",
          icons: "/images/modules-icons/bookeeping.png",
        },
        {
          module_id: 16,
          module_name: "MG",
          alias: "Management",
          icons: "/images/modules-icons/management.png",
        },
        {
          module_id: 17,
          module_name: "RRHH",
          alias: "Human Resources",
          icons: "/images/modules-icons/rrhh.png",
        },
        {
          module_id: 27,
          module_name: "CR",
          alias: "Creative",
          icons: "/images/modules-icons/creative.png",
        },
      ],
      showUserValidActivityModal: false,
    };
  },

  computed: {
    currentUserSelected() {
      return this.filters[0].model;
    },
    currentModuleSelected() {
      return this.filters[1].model;
    },
  },
  watch: {
    filterDate(newValue) {
      this.$refs.UsersLogTable.refresh();
      sessionStorage.setItem("filterDate", newValue);
    },
  },
  async created() {
    this.momentCurrentDate = moment();
    this.filterDate =
      sessionStorage.getItem("filterDate") || moment().format("YYYY-MM-DD");
    await Promise.all([await this.getUsers(), await this.getModules()]);
    this.moveCalendarIntoSeachboxContainer();
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async myProvider() {
      this.isBusy = true;
      let items = [];
      try {
        const params = {
          date: this.filterDate,
          user_id: this.currentUserSelected,
          active_user: this.$route.meta.active_user,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
          module_id: this.currentModule,
          search_text: this.filterPrincipal.model,
        };
        const { data } = await UsersLogService.getIndexData(params);
        this.fromPage = data.from;
        this.totalRows = data.total;
        this.toPage = data.to;
        this.isBusy = false;
        items = data.data;
        items = items.map((item) => ({
          ...item,
          start_login: item.start_login
            ? moment.utc(item.start_login).local().format()
            : null,
          end_logout: item.end_logout
            ? moment.utc(item.end_logout).local().format()
            : null,
        }));
        return items;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    async getUsers() {
      try {
        const params = {
          active_user: this.$route.meta.active_user,
          type: "get_users",
        };
        const { data } = await UsersLogService.getUsers(params);
        this.filters[0].options = [this.defaultUserOptions, ...data];
      } catch (error) {
        console.error(error);
      }
    },
    async getModules() {
      try {
        const params = { type: "get_modules" };
        const { data } = await UsersLogService.getModules(params);
        this.filters[1].options = [this.defaultModuleOptions, ...data];
      } catch (error) {
        console.error(error);
      }
    },
    addSearchIconInFilter() {
      const iconElement = document.createElement("i");
      iconElement.setAttribute("data-v-04a348xasdg48x", "");
      iconElement.classList.add("search-icon");
      const div = document.createElement("div");
      div.setAttribute("data-v-04a348xasdg48x", "");
      div.appendChild(iconElement);
      setTimeout(() => {
        const inputFilterParent = document.querySelector(
          "#inner-duo-filter-container"
        );
        const { firstChild } = inputFilterParent;
        inputFilterParent.insertBefore(iconElement, firstChild);
      }, 10);
    },
    moveCalendarIntoSeachboxContainer() {
      const calendarElement = document.getElementById("filter-date-selector");
      calendarElement.classList.remove("hide-filter-date-picker");
      const currentCalendarParentElement = document.getElementById(
        "filters-selector__pages"
      );
      const newCalendarParentElement = document.getElementById(
        "inner-duo-filter-parent-container"
      );
      currentCalendarParentElement.removeChild(calendarElement);
      newCalendarParentElement.appendChild(calendarElement);
    },
    getVariant(index, length, type) {
      switch (true) {
        case index === 0:
          return "primary";
        case index === length - 1 && type === "component":
          return "info";
        default:
          return "secondary";
      }
    },
    openUserActivityModal(userId, userPhoto, userName) {
      this.currentUserId = userId;
      this.currentUserPhoto = userPhoto;
      this.currentUserName = userName;
      this.showUserActivityModal = true;
    },
    closeUserActivityModal() {
      this.currentUserId = null;
      this.showUserActivityModal = false;
    },
    openUserValidActivityModal(userId, userPhoto, userName) {
      this.currentUserId = userId;
      this.currentUserPhoto = userPhoto;
      this.currentUserName = userName;
      this.showUserValidActivityModal = true;
    },
    closeUserValidActivityModal() {
      this.currentUserId = null;
      this.currentUserPhoto = null;
      this.currentUserName = null;
      this.showUserValidActivityModal = false;
    },
    openUserValidAbsenceModal(userId, userPhoto, userName) {
      this.currentUserId = userId;
      this.currentUserPhoto = userPhoto;
      this.currentUserName = userName;
      this.showUserValidAbsenceModal = true;
    },
    closeUserValidAbsenceModal() {
      this.currentUserId = null;
      this.currentUserPhoto = null;
      this.currentUserName = null;
      this.showUserValidAbsenceModal = false;
    },
    changeTabModule(moduleId) {
      this.currentModule = moduleId;
      this.$refs.UsersLogTable.refresh();
    },
  },
};
</script>

<style lang="scss">
.action-badge {
  &__inner-text {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 900;
  }
}

#filter-date-selector:last-child {
  order: -1 !important; /* This will move the item to the beginning of the row */
}

.search-icon {
  position: absolute;
  width: 22px;
  height: 22px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" stroke="%23ffffff" height="50px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg>')
    no-repeat;
  background-size: contain;
  left: 30px;
  z-index: 999;
}

.users-log-table #inner-duo-filter-parent-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
}

.dark-layout {
  .users-log-table .filter-date-picker {
    width: 160px !important;
    height: 50px !important;
    padding: 5px 10px !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    background-color: #272727 !important;
  }
}

.users-log-table .filter-date-picker {
  width: 160px !important;
  height: 50px !important;
  padding: 5px 10px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
}

.badge-pxy {
  font-weight: 900 !important;
  padding: 1.3rem 1.3rem !important;
}

.hide-filter-date-picker {
  display: none !important;
}
</style>

<template>
  <div class="user-activity-detailed">
    <b-modal
      ref="userLog"
      v-model="onControl"
      header-bg-variant="transparent border-bottom border-bottom-2"
      size="xls"
      header-class="p-0"
      title="'Title name'"
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close()"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div style="font-size: 1.8rem; font-weight: 900">
            <b-avatar
              :src="userPhoto"
              size="2.8rem"
              variant="light-primary"
              class="mr-1"
            />
            <span> User Activity - {{ userName }} </span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="close()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>

      <filter-slot
        id="filter-slot-detailed-activity"
        class="filter-slot-new-customization"
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="fromPage"
        :to-page="toPage"
        :top-paginate="false"
        :no-visible-principal-filter="true"
        @reload="$refs['userActivityTable'].refresh()"
      >
        <template #buttons-filter-end>
          <div class="d-flex">
            <b-form-datepicker
              v-model="filterDate"
              class="ml-1 filter-date-picker-detailed"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="en"
              today-button
            />
            <duration-time
              v-if="totalRouteDuration.total_duration_in_seconds > 0"
              :class-name="'ml-1 badge-pxy'"
              :duration="{
                days: totalRouteDuration.days,
                hours: totalRouteDuration.hours,
                minutes: totalRouteDuration.minutes,
                seconds: totalRouteDuration.seconds,
              }"
              :is-pill="false"
              :rounded="false"
              :label-description="'ACTIVE HOURS'"
            />
            <duration-time
              v-if="totalRouteAbsence.total_absence_in_seconds > 60"
              :class-name="'ml-1 badge-pxy'"
              :duration="{
                days: totalRouteAbsence.days,
                hours: totalRouteAbsence.hours,
                minutes: totalRouteAbsence.minutes,
                seconds: totalRouteAbsence.seconds,
              }"
              :is-pill="false"
              :rounded="false"
              :variant="'light-danger'"
              :tooltip-variant="'danger'"
              :label-description="'INACTIVE HOURS'"
            />
          </div>
        </template>
        <template #table>
          <b-table
            ref="userActivityTable"
            no-border-collapse
            class="position-relative table-new-customization"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="xmd"
            :items="myProvider"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #empty>
              <div class="text-center text-primary my-2">
                <strong>No existing data.</strong>
              </div>
            </template>
            <template #head(activity_path)="data">
              <div class="text-center">
                {{ data.label }}
                <feather-icon
                  icon="NavigationIcon"
                  size="14"
                  style="padding-left: 3px"
                />
              </div>
            </template>
            <template #head(total_duration_in_seconds)="data">
              <div class="text-center">
                {{ data.label }}
                <tabler-icon
                  icon="RotateClockwiseIcon"
                  size="14"
                />
              </div>
            </template>
            <template #head(total_absence_in_seconds)="data">
              <div class="text-center">
                {{ data.label }}
                <tabler-icon
                  icon="RotateClockwise2Icon"
                  size="14"
                />
              </div>
            </template>
            <template #head(created_at_time)="data">
              <div class="text-center">
                {{ data.label }}
                <feather-icon
                  icon="ClockIcon"
                  size="14"
                />
              </div>
            </template>
            <template #cell(activity_path)="data">
              <div class="text-center">
                <div class="d-flex align-items-center">
                  <route-part :navigation-path="data.item.activity_path" />
                </div>
              </div>
            </template>
            <template #cell(total_duration_in_seconds)="data">
              <div class="text-center">
                <div
                  v-if="data.item.total_duration_in_seconds !== null"
                  class="text-center"
                  style="font-weight: 900"
                >
                  <duration-time
                    :duration="{
                      days: data.item.duration_in_days,
                      hours: data.item.duration_in_hours,
                      minutes: data.item.duration_in_minutes,
                      seconds: data.item.duration_in_seconds,
                    }"
                    :variant="
                      isRoute(data.item.activity_path)
                        ? 'light-primary'
                        : 'light-secondary'
                    "
                    :tooltip-variant="
                      isRoute(data.item.activity_path) ? 'primary' : 'secondary'
                    "
                  />
                </div>
                <div v-else>
                  <b-badge
                    pill
                    variant="light-success"
                  >
                    <span class="text-center duration-time">
                      {{ "On Screen" }}
                    </span>
                  </b-badge>
                </div>
              </div>
            </template>
            <template #cell(total_absence_in_seconds)="data">
              <div class="text-center">
                <div
                  v-if="data.item.total_absence_in_seconds !== null && data.item.total_absence_in_seconds > 120"
                  class="text-center"
                  style="font-weight: 900"
                >
                  <duration-time
                    :duration="{
                      days: data.item.absence_in_days,
                      hours: data.item.absence_in_hours,
                      minutes: data.item.absence_in_minutes,
                      seconds: data.item.absence_in_seconds,
                    }"
                    :variant="'light-danger'"
                    tooltip-variant="danger"
                  />
                </div>
              </div>
              <div v-if="data.item.total_absence_in_seconds === null && data.item.total_duration_in_seconds !== null && isRoute(data.item.activity_path)">
                <div class="text-center">
                  <duration-time
                    v-if="calculateTimeDifference(data.item.created_at, data.item.total_duration_in_seconds).total_seconds > 60"
                    :duration="calculateTimeDifference(data.item.created_at, data.item.total_duration_in_seconds)"
                    :variant="'light-danger'"
                    tooltip-variant="danger"
                  />
                </div>
              </div>
            </template>
            <template #cell(created_at_time)="data">
              <div class="text-center">
                <span> {{ data.item.created_at_time }}</span>
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-modal>
  </div>
</template>

<script>
import UsersLogService from '@/views/management/views/users-log/services/users-log.service';
import TimeLapse from '@/views/management/views/users-log/components/TimeLapse.vue';
import RoutePart from '@/views/management/views/users-log/components/RoutePart.vue';
import durationTime from '@/views/management/views/users-log/components/duration.vue';
import moment from 'moment';

export default {
  components: {
    TimeLapse,
    RoutePart,
    durationTime,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    userPhoto: {
      type: String,
      default: null,
    },
    userName: {
      type: String,
      required: true,
    },
    date: {
      type: [String, Date],
      default: null,
    },
  },
  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields: [
        {
          key: 'activity_path',
          label: 'Navigation Path',
          sortable: false,
          visible: true,
        },
        {
          key: 'total_duration_in_seconds',
          label: 'Activity',
          sortable: false,
          visible: true,
        },
        {
          key: 'total_absence_in_seconds',
          label: 'Inactivity',
          sortable: false,
          visible: true,
        },
        {
          key: 'created_at_time',
          label: 'Start Time',
          sortable: false,
          visible: true,
        },
      ],
      filterDate: this.date,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by user or route...',
        model: '',
      },
      fromPage: null,
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      totalRouteDuration: {
        total_duration_in_seconds: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      totalRouteAbsence: {
        total_absence_in_seconds: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },

  computed: {},
  watch: {
    async filterDate(newDate, oldDate) {
      if (newDate === oldDate) return;
      this.$refs.userActivityTable.refresh();
    },
  },
  async created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.$refs.userLog.hide();
      this.onControl = false;
      this.$emit('close');
    },
    // eslint-disable-next-line consistent-return
    async myProvider() {
      this.isBusy = true;
      let items = [];
      try {
        const params = {
          date: this.filterDate,
          user_id: this.userId,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
          search_text: this.filterPrincipal.model,
          type: 'get_detailed_user_log',
        };
        const { data } = await UsersLogService.getUserDetailedLog(params);
        this.fromPage = data.from;
        this.toPage = data.to;
        this.totalRows = data.total;
        this.isBusy = false;

        this.totalRouteDuration = {
          total_duration_in_seconds:
            data.data?.[0]?.total_route_duration_in_seconds ?? 0,
          days: data.data?.[0]?.route_duration_in_days ?? 0,
          hours: data.data?.[0]?.route_duration_in_hours ?? 0,
          minutes: data.data?.[0]?.route_duration_in_minutes ?? 0,
          seconds: data.data?.[0]?.route_duration_in_seconds ?? 0,
        };

        this.totalRouteAbsence = {
          total_absence_in_seconds:
            data.data?.[0]?.total_route_absence_in_seconds ?? 0,
          days: data.data?.[0]?.route_absence_in_days ?? 0,
          hours: data.data?.[0]?.route_absence_in_hours ?? 0,
          minutes: data.data?.[0]?.route_absence_in_minutes ?? 0,
          seconds: data.data?.[0]?.route_absence_in_seconds ?? 0,
        };

        items = data.data;
        items = items.map(item => {
          const createdAt = moment.utc(item.created_at).local();
          return {
            ...item,
            created_at: createdAt.format('YYYY-MM-DD HH:mm:ss'),
            created_at_time: createdAt.format('h:mm A'),
          };
        });
        return items;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    addSearchIconInFilter() {
      const iconElement = document.createElement('i');
      iconElement.setAttribute('data-v-x0x4a348xasdg48bx', '');
      iconElement.classList.add('search-icon-detailed');
      const div = document.createElement('div');
      div.setAttribute('data-v-x0x4a348xasdg48bx', '');
      div.appendChild(iconElement);
      setTimeout(() => {
        const inputFilterParent = document.querySelector(
          '#filter-slot-detailed-activity #inner-duo-filter-container',
        );
        const { firstChild } = inputFilterParent;
        inputFilterParent.insertBefore(iconElement, firstChild);
      }, 20);
    },
    isRoute(activityPath) {
      const { length } = activityPath;
      const type = activityPath[length - 1].type_id;
      return type === 1;
    },
    calculateTimeDifference(dateTime1, secondsTranscurred) {
      const mDate1 = moment(dateTime1);
      mDate1.add(secondsTranscurred, 'seconds');
      const dateTime2 = moment();

      const duration = moment.duration(dateTime2.diff(mDate1));

      const diffInDays = duration.days();
      const diffInHours = duration.hours();
      const diffInMinutes = duration.minutes();
      const diffInSeconds = duration.seconds();

      const totalDurationInSeconds = duration.asSeconds();

      const remainingHours = diffInHours % 24;
      const remainingMinutes = diffInMinutes % 60;
      const remainingSeconds = diffInSeconds % 60;

      const timeDifference = {
        days: diffInDays < 0 ? diffInDays * -1 : diffInDays,
        hours: remainingHours < 0 ? remainingHours * -1 : remainingHours,
        minutes: remainingMinutes < 0 ? remainingMinutes * -1 : remainingMinutes,
        seconds: remainingSeconds < 0 ? remainingSeconds * -1 : remainingSeconds,
        total_seconds: totalDurationInSeconds,
      };
      return timeDifference;
    },
  },
};
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
.text-money {
  font-weight: 500;

  &::before {
    content: "$";
  }
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}

.charge__programs {
  width: 47px;
  height: 40px;
  background-color: #e9e9e9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
  }
}
</style>
<style lang="scss">
.b-table-bottom-row {
  position: sticky;
  bottom: 0px;
  z-index: 1;
}
.search-icon-detailed {
  position: absolute;
  width: 22px;
  height: 22px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" stroke="%23ffffff" height="50px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg>')
    no-repeat;
  background-size: contain;
  left: 30px;
  z-index: 999;
}
.dark-layout {
  .filter-date-picker-detailed {
    width: 160px !important;
    height: 50px !important;
    padding: 5px 10px !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    background-color: #272727 !important;
  }
}

.filter-date-picker-detailed {
  width: 160px !important;
  height: 50px !important;
  padding: 5px 10px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
}
</style>

<template>
  <b-modal
    ref="userLogActivity"
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="md"
    header-class="p-0"
    title="'Title name'"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close()"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div style="font-size: 1.8rem; font-weight: 900">
          <b-avatar
            :src="userPhoto"
            size="2.8rem"
            variant="light-primary"
            class="mr-1"
          />
          <span> User Valid Activity - {{ userName }} </span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      class="filter-slot-new-customization"
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :top-paginate="false"
      :no-visible-principal-filter="true"
      :to-page="toPage"
      @reload="$refs['refUserValidActivity'].refresh()"
    >
      <template #buttons-filter-end>
        <div class="d-flex">
          <b-form-datepicker
            v-model="filterDate"
            class="ml-1 filter-date-picker-detailed"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="en"
            today-button
          />
          <duration-time
            v-if="totalActiveSessionDuration.total_duration_in_seconds > 0"
            :class-name="'ml-1 badge-pxy'"
            :duration="{
              days: totalActiveSessionDuration.days,
              hours: totalActiveSessionDuration.hours,
              minutes: totalActiveSessionDuration.minutes,
              seconds: totalActiveSessionDuration.seconds,
            }"
            :is-pill="false"
            :rounded="false"
            :label-description="'ACTIVE HOURS'"
          />
        </div>
      </template>
      <template #table>
        <b-table
          ref="refUserValidActivity"
          no-border-collapse
          class="position-relative table-new-customization"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="myProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2 ">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-center text-primary my-2">
              <strong>No existing data.</strong>
            </div>
          </template>
          <template #head(start_datetime_session)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(end_datetime_session)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(individual_session_duration_in_seconds)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #cell(start_datetime_session)="data">
            <div class="text-center">
              <b-badge
                pill
                variant="light-success"
              >
                <span
                  style="font-size: 14px; font-weight: 900"
                  class="align-middle text-center"
                >
                  {{ data.item.start_datetime_session }}
                </span>
              </b-badge>
            </div>
          </template>
          <template #cell(end_datetime_session)="data">
            <div class="text-center">
              <b-badge
                pill
                variant="light-success"
              >
                <span
                  v-if="data.item.end_datetime_session !== null"
                  style="font-size: 14px; font-weight: 900"
                  class="align-middle text-center"
                >
                  {{ data.item.end_datetime_session }}
                </span>
                <span
                  v-else
                  style="font-size: 14px; font-weight: 900"
                  class="align-middle text-center"
                >
                  Still there
                </span>
              </b-badge>
            </div>
          </template>
          <template #cell(individual_session_duration_in_seconds)="data">
            <div
              v-if="data.item.individual_session_duration_in_seconds !== null"
              class="text-center"
            >
              <duration-time
                :duration="{
                  days: data.item.session_duration_in_days,
                  hours: data.item.session_duration_in_hours,
                  minutes: data.item.session_duration_in_minutes,
                  seconds: data.item.session_duration_in_seconds,
                }"
                :variant="'light-primary'"
                :tooltip-variant="'primary'"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>

import UsersLogService from '@/views/management/views/users-log/services/users-log.service';
import durationTime from '@/views/management/views/users-log/components/duration.vue';
import moment from 'moment';

export default {
  components: {
    durationTime,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    userPhoto: {
      type: String,
      default: null,
    },
    date: {
      type: [String, Date],
      default: null,
    },
  },
  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields: [
        {
          key: 'start_datetime_session',
          sortable: false,
          label: 'Start Time',
          visible: true,
        },
        {
          key: 'end_datetime_session',
          sortable: false,
          label: 'End Time',
          visible: true,
        },
        {
          key: 'individual_session_duration_in_seconds',
          sortable: false,
          label: 'Session Time',
          visible: true,
        },
      ],
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      filterDate: this.date,
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      totalActiveSessionDuration: {
        total_duration_in_seconds: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },

  computed: {},
  watch: {
    async filterDate(newDate, oldDate) {
      if (newDate === oldDate) return;
      this.$refs.refUserValidActivity.refresh();
    },
  },
  async created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.$refs.userLogActivity.hide();
      this.onControl = false;
      this.$emit('close');
    },
    async myProvider() {
      let items = [];
      const params = {
        user_id: this.userId,
        date: this.filterDate,
        type: 'get_activity_user_log',
        page: this.paginate.currentPage,
        perpage: this.paginate.perPage,
      };

      const { data } = await UsersLogService.getActivityUserLog(params);

      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      this.totalActiveSessionDuration = {
        total_duration_in_seconds: data.data?.[0]?.global_session_duration_in_seconds,
        days: data.data?.[0]?.total_session_duration_in_days,
        hours: data.data?.[0]?.total_session_duration_in_hours,
        minutes: data.data?.[0]?.total_session_duration_in_minutes,
        seconds: data.data?.[0]?.total_session_duration_in_seconds,
      };
      items = data.data;

      items = items.map(item => ({
        ...item,
        start_datetime_session: item.start_datetime_session ? moment.utc(item.start_datetime_session).local().format('h:mm A') : null,
        end_datetime_session: item.end_datetime_session ? moment.utc(item.end_datetime_session).local().format('h:mm A') : null,
      }));

      return items || [];
    },
  },

};
</script>
    <style lang="scss" scoped>
    .text-money {
        font-weight: 500;

        &::before {
            content: "$";
        }
    }

    .header-modal {
        position: relative;
        padding: 20px 0 20px 15px;
        background: rgba(22, 84, 204, 1);
        width: 100%;
        font-size: 22px;
        display: flex;
        justify-content: space-betweeen;

        span {
            font-size: 1.6rem;
            font-weight: bold;
            margin-left: 8px;
            color: #FFFFFF;
        }

        .container-icons {
            background-color: #FFFFFF;
            padding: 2.7px 6.37px;
            border-radius: 0.357rem;
            width: 31px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 15px;
            right: 20px;
            transition: all 0.23s ease 0.1s;

            &--dark {
                background-color: #17171a;
            }

            &:hover {
                transform: translate(-7px, 5px);
                cursor: pointer;
            }

        }
    }

    .charge__programs {
    width: 47px;
    height: 40px;
    background-color: #E9E9E9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

        img {
            width: 35px;
            height: 35px;
        }
    }
</style>
<style>
    .b-table-bottom-row {
    position: sticky;
    bottom: 0px;
    z-index: 1;
    }
</style>

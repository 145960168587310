<template>
  <div class="time-lapse">
    <div
      :id="`show-full-date-${date}`"
      :tooltip="date"
      class="time-lapse__current_date cursor-pointer"
    >
      <span
        v-if="yearsAgo > 0 "
        class="duration-time-lapse"
      >{{ yearsAgo }} year{{ yearsAgo > 1 ? 's' : '' }}</span>
      <span
        v-if="monthsAgo > 0 && yearsAgo < 1"
        class="duration-time-lapse"
      >{{ monthsAgo }} month{{ monthsAgo > 1 ? 's' : '' }}</span>
      <span
        v-if="daysAgo > 0 && monthsAgo < 1"
        class="duration-time-lapse"
      >{{ daysAgo }} day{{ daysAgo > 1 ? 's' : '' }}</span>
      <span
        v-if="hoursAgo > 0 && daysAgo < 1 && monthsAgo < 1 && yearsAgo < 1"
        class="duration-time-lapse"
      >{{ hoursAgo }} hour{{ hoursAgo > 1 ? 's' : '' }}</span>
      <span
        v-if="minutesAgo > 0 && hoursAgo < 1 && daysAgo < 1"
        class="duration-time-lapse"
      >{{ minutesAgo }} minute{{ minutesAgo > 1 ? 's' : '' }}</span>
      <span
        v-if="minutesAgo < 1 && hoursAgo < 1 && daysAgo < 1"
        class="duration-time-lapse"
      >{{ secondsAgo }} second{{ secondsAgo > 1 ? 's' : '' }}</span>
      <span class="duration-time-lapse"> ago</span>
    </div>
    <b-tooltip
      :target="`show-full-date-${date}`"
      triggers="hover"
      placement="top"
    >
      <div class="d-flex tooltip-origin-program-container">
        <div class="tooltip-origin-program-from">
          {{ date | myGlobalDay }}
        </div>
      </div>
    </b-tooltip>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  components: {

  },
  props: {
    date: {
      type: [Date, String],
      required: true,
    },
  },
  data() {
    return {
      currentMomentDate: null,
      isCurrentDate: false,
      yearsAgo: null,
      monthsAgo: null,
      daysAgo: null,
      hoursAgo: null,
      minutesAgo: null,
      secondsAgo: null,
    };
  },
  created() {
    const formattedDate = moment(this.date, 'YYYY-MM-DD');
    const currentMomentDate = moment().format('YYYY-MM-DD');
    const currentDateTime = moment();
    const timeDifference = currentDateTime.diff(this.date);
    const duration = moment.duration(timeDifference);
    const seconds = duration.seconds();
    const minutes = duration.minutes();
    const hours = Math.floor(duration.asHours());
    const days = duration.days();
    const months = duration.months();
    const years = duration.years();
    this.daysAgo = days;
    this.monthsAgo = months;
    this.yearsAgo = years;
    this.hoursAgo = hours;
    this.minutesAgo = minutes;
    this.secondsAgo = seconds;
    this.isCurrentDate = moment(formattedDate).isSame(currentMomentDate);
  },
  methods: {

  },
};
</script>
<style lang="scss">
    .time-lapse {
        &__current_date {
            font-weight: 900;
        }
    }
    .time-lapse .duration-time-lapse {
      font-size: 14px !important;
    }
</style>

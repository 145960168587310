export default [
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'User',
    model: null,
    options: [],
    reduce: 'user_id',
    selectText: 'user_name',
    cols: 12,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Modules',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
  },
];
